import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import {fetchBackend} from '../../helpers/fetch'
import { useAuth } from '@frontegg/react';
import '../../styles/wa-templates.css'
import '../../styles/cloud-files.css'


Modal.setAppElement('#root');

const WAPINewTemplateModal = () => {
    
    const { user } = useAuth();
    const { filesListsModalOpen, setFilesListsModalOpen, setCloudConnectionsList } = useContext(ElementContext)
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [accesDenied, setAccesDenied] = useState(false)

    const closeModal = () => {
        setFilesListsModalOpen(false)
    }

    const handleSendNewConnection = async(e) => {
        e.preventDefault()
        setAccesDenied(false)
        setLoading(true)
        const listname = await document.querySelector('#connectionName').value
        const fileUrl =  await document.querySelector('#fileUrl').value
        const platform = 'gdrive'
        const fileName = '-'
        
        const fileInfoRequest = await fetchBackend('cloud-file-info', {fileUrl}, 'POST')
        const fileInfoResponse = await fileInfoRequest.json()
        if(!fileInfoResponse.ok){
            setLoading(false)
            setAccesDenied(true)
            return
        }
        
        const newConnectionData = {
            fileUrl, platform, fileName, listname
        }

        const data = {
            email: await user.email,
            listname,
            connectionsData: newConnectionData
        }

        const saveNewConnRequest = await fetchBackend('save-file-list', {data}, 'POST')
        const saveNewConnRes = await saveNewConnRequest.json()
        if(saveNewConnRes.ok){
            const email = await user.email
            const listasRequest = await fetchBackend(`lists/${email}`);
            const listasResponse = await listasRequest.json();
            const fileLists = await listasResponse.data.filter( list => list.tipodelista === 'file' )
            const fileListData = await fileLists.map(fileList => JSON.parse(fileList.configuracion))

            for(let fileInfo in fileListData){
                const fileUrl = await fileListData[fileInfo].fileUrl
                const fileInfoRequest = await fetchBackend('cloud-file-info', {fileUrl}, 'POST')
                const fileInfoResponse = await fileInfoRequest.json()
                const fileName = await fileInfoResponse.fileMetaData.properties.title
                fileListData[fileInfo].fileName = fileName
            }
            setCloudConnectionsList(fileListData)
        }

        setFilesListsModalOpen(false)
        setLoading(false)
    }

    const handleCancelNewFile = (e) => {
        e.preventDefault()
        closeModal()
    }
    

  return (
        <Modal
            isOpen={filesListsModalOpen}
            closeTimeoutMS={ 200 }
            className="modal-new-template"
            overlayClassName="modal-fondo"
        >
            <h4 className='text-center text-mediumblue mb-0'>Nueva conexión de archivo</h4>
            <div className="d-flex justify-content-center">
                <small className='text-lightblue'>
                    Crea una nueva conexión con un archivo en la nube
                </small>
            </div>
            <hr />

            <form onSubmit={handleSendNewConnection}>
                <div className='new-connection-form'>
                    <div className='animate__animated animate__fadeInLeft'>
                        <div className="mb-3">
                            <label htmlFor="connectionName" className="form-label new-connection-label mb-0">
                                {t('listName')}
                            </label>
                            <input
                                autoComplete='off'
                                disabled={loading}
                                type="text"
                                className="form-control"
                                id="connectionName"
                                name='connectionName'
                                placeholder={t('addConnectionName')} />
                        </div>
                        
                        <div className="d-flex flex-column mb-3">
                            <label htmlFor="fileUrl" className="form-label new-connection-label mb-0">
                                {t('fileUrl')}
                            </label>
                            {
                                accesDenied && (
                                    <small className='text-danger mb-2'>
                                        No es posible acceder al archivo debido a falta de permisos. Por favor, compártelo con <strong>acceso público</strong> o al usuario <strong>google-spreadsheet@acquired-day-375212.iam.gserviceaccount.com</strong>
                                    </small>
                                )
                            }
                            <input
                                autoComplete='off'
                                disabled={loading}
                                type="text"
                                className="form-control"
                                id="fileUrl"
                                name='fileUrl'
                                placeholder={t('templateName')} />
                        </div>
                    
                        
                        <div className='mt-4 d-flex justify-content-center'>
                            <button
                                disabled={loading}
                                className='btn btn-sm btn-lightblue'
                            >
                                {
                                    loading ? (
                                        <>
                                          <span className='text-light'>{t('processing')}...</span>
                                          <div className="spinner-border text-light loading-button ms-2" role="status">
                                              <span className="visually-hidden">...</span>
                                          </div>
                                        </>
                                    ) : t('save')
                                }
                            </button>

                            <button
                                disabled={loading}
                                className='btn btn-sm btn-91 ms-3'
                                onClick={ handleCancelNewFile }
                            >
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
  )
}

export default WAPINewTemplateModal