import React, {useContext} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import { fetchBackend } from '../../helpers/fetch';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';

Modal.setAppElement('#root');

const ConfirmRemoveTemplate = ({idTemplate}) => {

    const { user } = useAuth();
    const { confirmRemoveTemplateModal, setConfirmRemoveTemplateModal, setTemplatesToShow, setChangesApplied, templateName, setTemplateName, setTemplateMessage, setAiTemplatesGenerated } = useContext(ElementContext);
    const { t } = useTranslation();

    const closeModal = () => {
        setConfirmRemoveTemplateModal(false)
    }

    const handleConfirm = async() => {
        await deleteTemplateById();
        await getTemplatesByTenant();
        setTemplateName('');
        setTemplateMessage('');
        setChangesApplied(false);
        closeModal();
    }


    const deleteTemplateById = async() => {
        const res = await fetchBackend( `delete-template/${idTemplate}`, {}, 'DELETE' )
        const bodyRes = await res.json();
        
        if( !bodyRes.ok ){
            Swal.fire('Oops', t('problem'), 'error')
        } else {
            Swal.fire(t('success'), t('templateRemoved'), 'success')
        }
        return
    }

    const getTemplatesByTenant = async() => {
        const email = await user.email
        
        // Obtener plantillas luego del guardado
        const resultado2 = await fetchBackend( `templates/${email}`, 'GET' )
        const body2 = await resultado2.json();
        
        const standardTemplates = await body2.data.filter( templates => templates.tipoplantilla === 'standard' || templates.tipoplantilla === 'ai-saved' )
        const aiTemplatesGenerated = await body2.data.filter( templates => templates.tipoplantilla === 'ai-generated' )
        setTemplatesToShow(standardTemplates)
        setAiTemplatesGenerated(aiTemplatesGenerated)
    }


  return (
        <Modal
            isOpen={confirmRemoveTemplateModal}
            onRequestClose={closeModal}
            closeTimeoutMS={ 200 }
            className="modal-confirm p-5"
            overlayClassName="modal-fondo"
        >
            <h5 className='text-center'>{t('confirmRemoveList1')} <span className='text-lightblue'>{templateName}</span> {t('confirmRemoveList2')}</h5>
            <h4 className='text-center'>{t('areYouSure')}</h4>
            <div className='d-flex justify-content-center'>
                <button
                    className='btn btn-sm btn-mediumblue mt-3 me-2'
                    onClick={ handleConfirm }
                >
                    {t('remove')}
                </button>

                
                <button
                    className='btn btn-sm btn-91 mt-3'
                    onClick={ closeModal }
                >
                    {t('cancel')}
                </button>
            </div>
            
        </Modal>
  )
}

export default ConfirmRemoveTemplate