import React, { useContext, useState } from 'react';
import {ElementContext} from './ElementContext'
import {fetchBackend} from '../helpers/fetch'
import Swal from 'sweetalert2'
import ConfirmModalLists from './modals/ConfirmModalLists';
import NuevaListaModal from './modals/NuevaListaModal';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';


const GroupList = () => {

  const { user } = useAuth();
  const { setInfoToShow, modalIsOpen, setModalIsOpen, listNames, confirm, setConfirmModal, setIsActive, activeList, setActiveList, setNameActive, changesApplied, setRowSelected, selectedGroupList, setSelectedGroupList, allTenantContacts, setListNames } = useContext(ElementContext);
  
  const { t } = useTranslation();
  const [editGroupName, setEditGroupName] = useState(null)
  const [newGroupName, setNewGroupName] = useState(null)

  const handleAddList = async(registro, index) => {
    setRowSelected(false)
    if(changesApplied){
      Swal.fire(t('warning'), t('saveChanges'), 'warning')
    } else {
      const idList = parseInt(registro.idlista);
      const nameList = registro.nombrelista;
      setActiveList(idList)
      setNameActive(nameList)
  
      const infoArray = allTenantContacts;
      const existe = infoArray.some( item => item.idlista === idList )      
  
      if(existe){
        const infoContactos = infoArray.filter( info => info.idlista === idList )
        setInfoToShow(infoContactos)
        setIsActive(infoContactos)
      } else {
        setInfoToShow(null)
        setIsActive(null)
      }
  
      if( selectedGroupList !== index ){
        setSelectedGroupList(index)
      }
    }
  }
  
  
  const handleRemoveGroup = () => {
    setRowSelected(false)
    if( !confirm ){
      setConfirmModal(true)
      setSelectedGroupList(null)
    }
    return
  }

  const handleAddGroup = () => {
    setRowSelected(false)
    if(changesApplied){
      Swal.fire(t('warning'), t('saveChanges'), 'warning')
    } else {
      setModalIsOpen(!modalIsOpen)
    }
    return
  }

  const handleEditGroupName = (lista) => {
    setEditGroupName(lista.idlista)
    setNewGroupName(lista.nombrelista)
  }

  const handleFinishEditGroupName = async(lista) => {
    const data = {
      newListName: newGroupName,
      email: await user.email
    }
    const listId = await lista.idlista
    const updateRequest = await fetchBackend(`lists/${listId}`, data, 'PUT')
    const updateResponse = await updateRequest.json()
    
    if( updateResponse.ok ){
      const newLists = await updateResponse.data.lists
      setListNames(newLists)
      setEditGroupName(null)
      setNewGroupName(null)
    }
  }

  return (
    <div id='table' className='col-3 py-2 pe-0 table-height'>
      <div className='btn-group d-flex'>
        <button
          className='btn btn-sm btn-outline-info'
          onClick={ handleAddGroup }
        >
          {t('newList')}
        </button>
        <button
          className='btn btn-sm btn-mediumblue'
          onClick={ handleRemoveGroup }
          disabled={ !activeList }
        >
          {t('removeList')}
        </button>
      </div>

      <table
        className='table p-0 mt-3'>
        <thead>
          <tr className='text-91'>
            <th><small>#ID</small></th>
            <th><small>{t('listName')}</small></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            listNames.map((registro, index) => (
              <tr
                key={registro.idlista}
                className={
                  ( selectedGroupList === index )
                  ? 'bg-91-table borderside-darkblue pointer'
                  : ( index % 2 === 0 ) ? 'bg-lightblue-table pointer' : 'bg-mediumblue-table pointer'
                }
                onClick={ () => handleAddList(registro, index) }
              >
                <th><small>{registro.idlista}</small></th>
                <td>
                  {
                    editGroupName === registro.idlista ? (
                      <input
                        type="text"
                        className='edit-input form-control'
                        value={newGroupName}
                        onChange={ (e) => setNewGroupName(e.target.value) }
                        maxLength={100}
                      />
                    ) : (
                      <small className='edited-input'>{registro.nombrelista}</small>
                    )
                  }
                </td>
                {
                  editGroupName === registro.idlista ? (
                    <td onClick={ () => handleFinishEditGroupName(registro) }>
                      <i className='bx bx-check check-icon pointer'></i>
                    </td>
                  ) : (
                    <td onClick={ () => handleEditGroupName(registro) }>
                      <i className='bx bx-edit edit-icon pointer'></i>
                    </td>
                  )
                }
              </tr>
            ))
          }
        </tbody>
      </table>

      <ConfirmModalLists />
      <NuevaListaModal />
    </div>
  )
}

export default GroupList