import React, {useContext, useState} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import Swal from 'sweetalert2'
import { fetchBackend } from '../../helpers/fetch';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@frontegg/react';


Modal.setAppElement('#root');

const TemplateModal = ({buttonSelected, message = '', type = ''}) => {

    const { user } = useAuth();
    const { templateModal, setTemplateModal, setTemplatesToShow, madeByUser, templatesToShow, setMessageWithTemplate, setAiTemplatesGenerated, setMessage } = useContext(ElementContext);
    const { t } = useTranslation();
    const [nombrePlantilla, setNombrePlantilla] = useState('')

    const closeModal = () => {
        setTemplateModal(false)
    }

    const handleCloseModal = (e) => {
        e.preventDefault();
        setTemplateModal(false)
    }

    const handleInputChange = (e) => {
        setNombrePlantilla(e.target.value)
    }


    const handleSubmit = async(e) => {
        e.preventDefault();
        const email = await user.email

        let data = [{
            idmensajegenerico: '#ID',
            nombreplantilla: nombrePlantilla,
            mensajegenerico: message.trim(),
            email,
            tipoplantilla: type
        }]
        
        const resultado = await fetchBackend( 'templates', {data}, 'POST' )
        const body = await resultado.json();

        if( body.ok ){
            Swal.fire(t('success'), t('savedTemplate'), 'success');
        } else {
            Swal.fire('Oops', t('problem'), 'error')
        }

        // Obtener plantillas luego del guardado
        const resultado2 = await fetchBackend( `templates/${email}`, 'GET' )
        const body2 = await resultado2.json();
        
        const standardTemplates = await body2.data.filter( templates => templates.tipoplantilla === 'standard' || templates.tipoplantilla === 'ai-saved' )
        console.log(standardTemplates)
        const aiTemplatesGenerated = await body2.data.filter( templates => templates.tipoplantilla === 'ai-generated' )
        setTemplatesToShow(standardTemplates)
        setAiTemplatesGenerated(aiTemplatesGenerated)

        closeModal()
    }

    const handleAddTemplateToForm = (template) => {
        setMessageWithTemplate(template.mensajegenerico);
        setMessage(template.mensajegenerico)
        closeModal();
    }


  return (
        <Modal
            isOpen={templateModal}
            onRequestClose={closeModal}
            closeTimeoutMS={ 200 }
            className="modal-template"
            overlayClassName="modal-fondo"
        >
            {
                (buttonSelected === 'opentemplate') ? (
                    <div>
                        <h4 className='text-center'>{t('selectTemplate')}</h4>
                        
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th className='ps-4'>#ID</th>
                                <th>{t('templateName')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                templatesToShow.map(template => (
                                <tr
                                    className='pointer'
                                    key={template.idmensajegenerico}
                                    onClick={ () => handleAddTemplateToForm(template) }
                                >
                                    <th className='ps-4'>{template.idmensajegenerico}</th>
                                    <td>{template.nombreplantilla}</td>
                                </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>
                        <h4 className='text-mediumblue text-center'>{t('saveAsTemplate')}</h4>
                        
                        <form onSubmit={handleSubmit}>
                            <div className='d-flex flex-column template-modal-input mt-4'>
                                <label htmlFor="templateName" className='text-center mb-2'>{t('nameYourTemplate')}</label>
                                <input
                                    autoComplete='off'
                                    type="text"
                                    name="templateName"
                                    id="templateName"
                                    onChange={ handleInputChange }
                                    placeholder={ t('templateName') }
                                />
                            </div>

                            <div className='d-flex justify-content-center mt-4'>
                                <button
                                    type='submit'
                                    className='btn btn-sm btn-mediumblue me-2'
                                >
                                    {t('confirm')}
                                </button>
            
                                <button
                                    className='btn btn-sm btn-secondary'
                                    onClick={ handleCloseModal }
                                >
                                    {t('cancel')}
                                </button>
                            </div>
                        </form>
                    </div>
                )
            }

        </Modal>
  )
}

export default TemplateModal