import React from 'react';
import AppRouter from './components/AppRouter';
import { ElementContextProvider } from './components/ElementContext';
import { useAuth } from '@frontegg/react'
import CustomSignUpForm from './components/CustomSignUpForm';
import './styles/modal.css';



const Application = ({pythonBackendUrl}) => {
  const { isAuthenticated, user } = useAuth();

  return (
    <div>
      {
        isAuthenticated ? (
          <ElementContextProvider>
              <AppRouter />
          </ElementContextProvider>
      ) : (
        window.location.href.includes('redirect') || window.location.href.includes('plan') || window.location.href.includes('success') || window.location.href.includes('canceled')
            ? <CustomSignUpForm />
            : window.location.replace('/account/login')
      )
    }
    </div>
  )
};

export default Application;