import React, { useCallback, useMemo, useRef, useState, useContext } from 'react';
import {ElementContext} from './ElementContext'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { fetchBackend } from '../helpers/fetch';
import Swal from 'sweetalert2';
import * as xlsx from 'xlsx'
import moment from 'moment'
import 'moment/locale/es'
import { DateTime } from 'luxon';
import ConfirmRemoveTemplate from './modals/ConfirmRemoveTemplate';
import { useTranslation } from 'react-i18next';
import { TemplateTypeIconRenderer } from './CellRenderer';
import { useAuth } from '@frontegg/react';


class dateTimeFormater {
  init(params) {
      const element = document.createElement('span');

      if(params.value){
        const dateFormated = moment(params.value).locale('es').format('L')
        element.innerText = dateFormated
        this.eGui = element;
      }
  }
  getGui() {
      return this.eGui;
  }
}


const timeZone = DateTime.now().zoneName;

const createNewRowData = () => {
  const newData = {
    idmensajegenerico: '#ID',
    fechacreacion: DateTime.now().setZone(timeZone),
    nombreplantilla: '',
    mensajegenerico: '',
    tipoplantilla: 'standard'
  };
  return newData;
};


const GestionPlantillas = () => {

  const { templatesToShow, setTemplatesToShow, changesApplied, setChangesApplied, madeByUser, tenantId, setConfirmRemoveTemplateModal, setTemplateName, setTemplateMessage, toggleSidebar, globalEnv, setAiTemplatesGenerated } = useContext(ElementContext);
  const { user } = useAuth();
  
  const { t } = useTranslation();
  const [idSelectedData, setIdSelectedData] = useState(null)
  const [excelFileUploaded, setExcelFileUploaded] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [templatesToSave, setTemplatesToSave] = useState([])

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '72vh', marginTop: '5px' }), []);
  const [columnDefs] = useState([
    { headerName: t('templateType'),
      field: 'tipoplantilla',
      editable: false,
      sortable: true,
      filter: true,
      valueGetter: () => {
        const translatedTexts = {
          'standardTemplate': t('standardTemplate'),
          'aiTemplate': t('aiTemplate')
        }
        return translatedTexts
      },
      cellRenderer: TemplateTypeIconRenderer,
    },
    {
      headerName: t('createdDate'),
      field: 'fechacreacion',
      editable: false,
      sortable: true,
      filter: true,
      cellRenderer: dateTimeFormater
    },
    { headerName: t('templateName'), field: 'nombreplantilla', editable: true, sortable: true, filter: true },
    {
      headerName: t('message'),
      field: 'mensajegenerico',
      editable: true,
      sortable: true,
      filter: true,
      minWidth: 650,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: { maxLength: globalEnv.MSG_MAX_LENGTH }
    },
  ]);

  let defaultColDef = useMemo(() => {
    return {
      flex: 1
    };
  }, []);

  const addItems = useCallback((addIndex) => {
    const newItems = [
      createNewRowData()
    ];

    gridRef.current.api.applyTransaction({
      add: newItems,
      addIndex: addIndex,
    });

    setChangesApplied(true)
  }, [setChangesApplied]);
  

  // Eliminar una plantilla
  const onRemoveSelected = useCallback(() => {
    const selectedData = gridRef.current.api.getSelectedRows();
    setIdSelectedData(selectedData[0].idmensajegenerico);
    setTemplateName(selectedData[0].nombreplantilla);

    setConfirmRemoveTemplateModal(true)

    setChangesApplied(true)
    setIsSelected(false)
  }, [setChangesApplied, setConfirmRemoveTemplateModal, setTemplateName]);

  
  // Exportar a Excel
  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
    console.log(gridRef.current.api);
    setIsSelected(false);
  }, []);


  // Eliminar todas las plantillas de un Tenant
  const deleteData = async() => {
    const email = await user.email
    const resultado = await fetchBackend( `delete-templates/${email}`, {}, 'DELETE' )
    const body = await resultado.json();

    if( !body.ok ){
      return Swal.fire('Oops', t('problem'), 'error')
    }
  }
  
  // Guardar una plantilla nueva
  const saveNewTemplate = async() => {
    setExcelFileUploaded(false);
    const dataRowChanged = await changeGenericIdBeforeSave();

    const data = templatesToSave.map(template => {
      if(template.idmensajegenerico === '#ID'){
        return dataRowChanged
      } else {
        return template
      }
    })

    const res = await fetchBackend( 'templates', {data}, 'POST' )
    const body = await res.json();
    
    if( !body.ok ){
        Swal.fire('¡Oops!', t('problem'), 'error')
    } else {
        Swal.fire(t('success'), t('savedTemplate'), 'success')
    }

    // Obtener plantillas luego del guardado
    const email = await user.email
    const resultado2 = await fetchBackend( `templates/${email}`, 'GET' )
    const body2 = await resultado2.json();
    
    const standardTemplates = await body2.data.filter( templates => templates.tipoplantilla === 'standard' || templates.tipoplantilla === 'ai-saved' )
    const aiTemplatesGenerated = await body2.data.filter( templates => templates.tipoplantilla === 'ai-generated' )
    setTemplatesToShow(standardTemplates)
    setAiTemplatesGenerated(aiTemplatesGenerated)
    setTemplatesToSave([])
  }


  const saveTemplatesFromExcel = async() => {
    const data = templatesToShow.filter( info => info.idmensajegenerico === '#ID' )
    
    const res = await fetchBackend( 'templates', {data}, 'POST' )
    const body = await res.json();
    
    if( !body.ok ){
        Swal.fire('Oops', t('problem'), 'error')
    } else {
        Swal.fire(t('success'), t('savedTemplate'), 'success')
    }

    // Obtener plantillas luego del guardado
    const email = await user.email
    const resultado2 = await fetchBackend( `templates/${email}`, 'GET' )
    const body2 = await resultado2.json();
    
    const standardTemplates = await body2.data.filter( templates => templates.tipoplantilla === 'standard' || templates.tipoplantilla === 'ai-saved' )
    const aiTemplatesGenerated = await body2.data.filter( templates => templates.tipoplantilla === 'ai-generated' )
    setTemplatesToShow(standardTemplates)
    setAiTemplatesGenerated(aiTemplatesGenerated)
    setTemplatesToSave([])

    setExcelFileUploaded(false)
  }


  const handleSaveInfo = async() => {
    gridRef.current.api.stopEditing();

    const container = document.querySelectorAll('.ag-center-cols-container .ag-row');

    if(excelFileUploaded){
      await saveTemplatesFromExcel();
      setChangesApplied(false);
      return
    }

    if(!(container[0].innerText)){ //Si la información es vacío, se ejecuta la eliminación
      await deleteData();
      Swal.fire(t('success'), t('templatesDeleted'), 'success')
    } else {
      await saveNewTemplate();
    }
    
    // console.log(gridRef.current.api.applyTransaction)
    gridRef.current.api.refreshCells({
      force: true,
      columns: []
    })

    setTemplateName('');
    setTemplateMessage('');
    setChangesApplied(false);
    setIsSelected(false);
  }


  // Botón CANCELAR devuelve la info tal y como estaba antes de haberlo modificado
  // Información recuperada directamente de la base de datos
  const handleCancelButton = async() => {
    setTemplatesToShow([]);

    // Obtener plantillas luego del guardado
    const email = await user.email
    const resultado2 = await fetchBackend( `templates/${email}`, 'GET' )
    const body2 = await resultado2.json();
    
    const standardTemplates = await body2.data.filter( templates => templates.tipoplantilla === 'standard' || templates.tipoplantilla === 'ai-saved' )
    const aiTemplatesGenerated = await body2.data.filter( templates => templates.tipoplantilla === 'ai-generated' )
    setTemplatesToShow(standardTemplates)
    setAiTemplatesGenerated(aiTemplatesGenerated)

    setChangesApplied(false);
    setTemplatesToSave([]);
    setIsSelected(false);
  }


  const readUploadFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0]

    // Validar que sea un archivo Excel
    if( file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ){
      console.log("El archivo seleccionado no es un EXCEL")
      Swal.fire('Oops', t('isnotExcelFile'), 'error')
      return
    }

    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);

          let dataFromFile = [...templatesToShow];

          for(let elem of json){
            dataFromFile = [
              ...dataFromFile,
              {
                idmensajegenerico: '#ID',
                nombreplantilla: elem.nombre,
                mensajegenerico: elem.mensaje,
                usuario: madeByUser,
                tenantid: tenantId,
                fechacreacion: DateTime.now().setZone(timeZone)
              }
            ]
          }

          setTemplatesToShow(dataFromFile)
          setExcelFileUploaded(true)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
    
    // Input files reset
    document.querySelector('#upload').value = '';
    setChangesApplied(true);
    setIsSelected(false);
  }

  const handleCellChange = async(params) => {
    // const focusedCell = await params.api.getFocusedCell().rowIndex;
    const focusedData = await params.api.getSelectedRows()[0]

    const alreadyExists = templatesToSave.some( template => template.idmensajegenerico === focusedData.idmensajegenerico )

    if(alreadyExists){
      const replicaTemplatesToSave = templatesToSave.filter( template => template.idmensajegenerico !== focusedData.idmensajegenerico )
      setTemplatesToSave([
        ...replicaTemplatesToSave,
        focusedData
      ])
    } else {
      setTemplatesToSave([
        ...templatesToSave,
        focusedData
      ])
    }

    setChangesApplied(true)
  }
  
  const changeGenericIdBeforeSave = () => {
    let newRowData;
    const existsGenericId = templatesToSave.some( template => template.idmensajegenerico === '#ID' )

    if(existsGenericId){
      const rowData = templatesToSave.filter( template => template.idmensajegenerico === '#ID' )[0]
      newRowData = {
        ...rowData,
        tenantid: tenantId,
        usuario: madeByUser
      }
    }

    return newRowData;
  }

  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  
  const onGridReady = useCallback((params) => {
    sizeToFit()
    autoSizeAll()
  }, [sizeToFit, autoSizeAll]);


  const handleSelected = () => {
    setIsSelected(true)
  }


  return (
    <div className={ toggleSidebar ? 'home animate__animated animate__fadeIn' : 'home animate__animated animate__fadeIn ms-4'} style={containerStyle}>

        <div className='mb-4'>
            <div className="text">
                {t('templatesTitle')}
            </div>
            <p className='subtitle mb-0'>
                {t('templatesSubtitle')}
            </p>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px'}}>

          <div className='d-flex justify-content-between'>
            <button
              id='addButton'
              className='btn btn-sm btn-lightblue w-25 me-1'
              onClick={addItems}
              disabled={ changesApplied }
            >
              {t('add')}
            </button>

            <button
              className='btn btn-sm btn-darkblue-static w-25 me-1'
              onClick={onRemoveSelected}
              disabled={!isSelected}
            >
                {t('remove')}
            </button>
            <div className="btn-group w-50">
              <label
                htmlFor="upload"
                className='btn btn-sm btn-outline-mediumblue d-flex align-items-center justify-content-center'>
                  {t('import')}
              </label>
              <input
                type="file"
                name="upload"
                id="upload"
                onChange={readUploadFile}
                className='d-none'
              />
              <button
                className='btn btn-sm btn-outline-darkblue'
                onClick={onBtExport}
              >
                {t('export')}
              </button>
            </div>
          </div>

          {/* <div style={{ flexGrow: '1' }}> */}
          <div>
            <div style={gridStyle} className="ag-theme-alpine">
              <AgGridReact
                ref={gridRef}
                rowData={templatesToShow}
                columnDefs={columnDefs}
                onCellValueChanged={ handleCellChange }
                defaultColDef={defaultColDef}
                rowSelection={'single'}
                animateRows={true}
                enableRangeSelection={true}
                suppressClipboardPaste={false}
                suppressAggFuncInHeader={true}
                enableCellChangeFlash={true}
                suppressChangeDetection={false}
                onGridReady={onGridReady}
                onRowSelected={handleSelected}
                stopEditingWhenCellsLoseFocus={true}
              ></AgGridReact>
            </div>
          </div>
        </div>
        
        <div
          className="d-flex justify-content-end align-items-center pt-3"
          style={{ marginRight: '100px'}}
          onMouseEnter={ () => gridRef.current.api.stopEditing() }
        >
          {
            changesApplied ? (
              <div className="alert alert-darkblue mb-0 p-alert me-2" role="alert">
                {t('someChanges')}
              </div>
            ) : null
          }
          <button
            className='btn btn-outline-lightblue-white btn-sm'
            onClick={ handleSaveInfo }
            disabled={ !changesApplied }
          >
            {t('save')}
          </button>

          <button
            className='btn btn-91 btn-sm ms-1'
            onClick={ handleCancelButton }
            disabled={ !changesApplied }
          >
            {t('cancel')}
          </button>
        </div>

        <ConfirmRemoveTemplate idTemplate={idSelectedData} />
    </div>
  );
};

export default GestionPlantillas;