import React, {useContext} from 'react'
import { ElementContext } from '../ElementContext';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';


Modal.setAppElement('#root');


const InfoModal = ({infoSelected}) => {

    const { showInfoModal, setShowInfoModal } = useContext(ElementContext);
    const { t, i18n } = useTranslation();


    const closeModal = () => {
        setShowInfoModal(false)
    }

    if(infoSelected === 'customMessages'){
        return (
              <Modal
                  isOpen={showInfoModal}
                  onRequestClose={closeModal}
                  closeTimeoutMS={ 200 }
                  className="modal-info p-5"
                  overlayClassName="modal-fondo"
              >
                  <h5 className='text-center text-blackseidor'>{t('infoModalTitle')}</h5>
      
                  <div className="text-blackseidor mt-4">
                      <ul>
                          <li>{t('infoModalMsg1')}: <strong>${t('name').toUpperCase()}$</strong>, <strong>${t('lastname').toUpperCase()}$</strong>, <strong>${t('company').toUpperCase()}$</strong>, <strong>${t('position').toUpperCase()}$</strong>, <strong>${t('country').toUpperCase()}$</strong> {t('and')} <strong>${t('observations').toUpperCase()}$</strong></li>
                          <li>{t('infoModalMsg2')}.</li>
                      </ul>
                  </div>
      
                  <div className="text-blackseidor text-center mt-2">
                      <strong><u>{t('important')}:</u></strong> {t('infoModalMsg3')}.
                  </div>
      
                  <div className='d-flex justify-content-center mt-4'>
                      <button
                          className='btn btn-sm btn-lightblue'
                          onClick={ closeModal }
                      >
                          {t('gotIt')}
                      </button>
                  </div>
                  
              </Modal>
        )
    }

    
    if(infoSelected === 'importantInfo'){
        return (
              <Modal
                  isOpen={showInfoModal}
                  onRequestClose={closeModal}
                  closeTimeoutMS={ 200 }
                  className="modal-important-info p-5"
                  overlayClassName="modal-fondo"
              >
                  <h5 className='text-center text-blackseidor'>
                    {t('importantInfoTitle')}
                  </h5>
      
                  <div className="text-blackseidor mt-4">
                      <ul>
                          <li>
                            {t('importantInfoBody1')}.
                          </li>
                          <li>
                            {t('importantInfoBody2')} 
                            {
                                i18n.resolvedLanguage === 'es' ? (
                                    <>
                                        <strong className='mx-1'><u>+54</u></strong>1153458267.
                                    </>
                                ) : (
                                    <>
                                        <strong className='mx-1'><u>+1</u> (555) </strong>074 2531 or <strong className='mx-1'><u>+91</u></strong>98 10597886
                                    </>
                                )
                            }
                          </li>
                          <li>
                            {t('importantInfoBody3')}.
                          </li>
                      </ul>
                  </div>
      
                  <div className='d-flex justify-content-center mt-4'>
                      <button
                          className='btn btn-sm btn-lightblue'
                          onClick={ closeModal }
                      >
                          {t('gotIt')}
                      </button>
                  </div>
                  
              </Modal>
        )
    }
    
    return (
            <div></div>
        )
}

export default InfoModal